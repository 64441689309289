import React, { Fragment, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';

// components
import TextPage from '../TextPage'; // function
import VideoGrid from '../VideoGrid';
import VideoPlayer from './../VideoPlayer'; // hook
import PasswordField from './PasswordField'; // hook
import Spinner from './Spinner'; // styled

// custom hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useDocumentMeta from '../../hooks/useDocumentMeta';

const SimpleWrapper = styled(({ className, children }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
`;

const Title = styled.h2`
  font-size: 22px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-bottom: 10px;
`;

const preloadImages = async images => {
  for (let i = 0; i < images.length; i++) {
    const img = new Image();
    img.src = images[i];
    await new Promise(resolve => (img.onload = resolve));
  }
  return true;
};

function Page(props) {
  const [page, setPage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  // router
  const { match, location } = useReactRouter();

  async function fetchPage() {
    try {
      setLoading(true);
      const res = await fetch(
        process.env.REACT_APP_ENDPOINT +
          '/wp-json/andrew/v1/page/' +
          match.params.page
      );
      const fetchedPage = await res.json();

      // if video, preload the thumbs too
      if (fetchedPage.type === 'video') {
        const thumbs = fetchedPage.videos.map(v => v.poster.sizes.medium_large);
        await preloadImages(thumbs);
      }
      // console.log(page);
      setPage(fetchedPage);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  // fetch page!
  // only if menu is ready
  // and only if we have a page
  // and react to changes on pathname and menuReady
  useEffect(() => {
    if (props.menuReady === true && match.params.page !== undefined)
      fetchPage();
  }, [location.pathname, props.menuReady]);

  /**
   * Loading page / no page
   */
  if (loading === true || page === undefined || location.pathname === '/') {
    useDocumentTitle(`Loading...`);
    useDocumentMeta('description', '');
    useDocumentMeta('keywords', '');
    return (
      <SimpleWrapper>
        <Spinner />
      </SimpleWrapper>
    );
  }

  /**
   * Not found page
   */
  if (page.notFound === true) {
    useDocumentTitle(`Page not found`);
    useDocumentMeta('description', '');
    useDocumentMeta('keywords', '');
    return (
      <SimpleWrapper>
        <Title>Page not found</Title>
      </SimpleWrapper>
    );
  }

  /**
   * Auth page
   */
  if (page.authed === false) {
    useDocumentTitle(`Enter Password | Andrew Grant-Christensen`);
    useDocumentMeta('description', '');
    useDocumentMeta('keywords', '');
    return (
      <SimpleWrapper>
        <Title>Password</Title>
        <PasswordField slug={page.slug} updatePage={setPage} />
      </SimpleWrapper>
    );
  }

  // set meta
  useDocumentTitle(`${page.title}`);
  useDocumentMeta('description', page.meta_description || '');
  useDocumentMeta('keywords', page.meta_tags || '');

  if (page.type === 'video') {
    /**
     * No videos page
     */
    if (page.videos.length === 0) {
      return (
        <SimpleWrapper>
          <Title>No videos</Title>
        </SimpleWrapper>
      );
    }

    /**
     * Video grid page
     */
    return (
      <Fragment>
        <VideoGrid page={page} />
        <Route
          path="/:page/:video"
          render={routeProps => (
            <VideoPlayer {...routeProps} videos={page.videos} />
          )}
        />
      </Fragment>
    );
  }

  if (page.type === 'text') {
    return (
      <TextPage
        col1={page.column_1}
        col2={page.column_2}
        title={page.page_title}
      />
    );
  }
}

export default Page;
