import React, { useState } from 'react';
import useFormInput from '../../hooks/useFormInput';
import styled from 'styled-components';

import Spinner from './Spinner';

const InputWrapper = styled.div`
  display: flex;
  max-width: 275px;
  width: 100%;
`;
const Input = styled.input`
  background-color: ${p => (p.error ? '#ffefef' : '#eeeeee')};
  color: ${p => (p.error ? '#9a6666' : '#999999')};
  font-size: 32px;
  padding: 0px 10px 6px 10px;
  border-radius: 5px;
  border: 0;
  outline: none;
  width: 100%;
  margin-bottom: 80px;
`;

const Submit = styled(({ className }) => (
  <svg className={className} viewBox="0 0 22 22">
    <path d="M0 11 L21 11" stroke="black" strokeWidth="2" />
    <path
      d="M11 0 L21 11 L11 22"
      stroke="black"
      fill="transparent"
      strokeWidth="2"
    />
  </svg>
))`
  width: 26px;
  height: 26px;
  margin-left: 10px;
  margin-top: 9px;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

function PasswordField(props) {
  const password = useFormInput('');
  const [badPassword, setBadPassword] = useState(false);
  const [working, setWorking] = useState(false);

  // make wrapper function for handlePasswordChange, so we can reset the bad password
  function handlePasswordChange(e) {
    password.onChange(e);
    setBadPassword(false);
  }

  async function handleAuth() {
    setWorking(true);
    // make a formdata with password for the POST request
    const formdata = new FormData();
    formdata.append('password', password.value);

    // try to auth the page
    const res = await fetch(
      process.env.REACT_APP_ENDPOINT + '/wp-json/andrew/v1/route/' + props.slug,
      {
        method: 'POST',
        body: formdata
      }
    );

    // if we get an OK, we can update the Page component with the authed data
    if (res.status === 200) {
      const { page } = await res.json();
      props.updatePage(page);
    } else {
      setBadPassword(true);
      setWorking(false);
    }
  }

  function handleKeypress(e) {
    if (e.key === 'Enter') handleAuth();
  }

  return (
    <InputWrapper>
      <Input
        type="password"
        value={password.value}
        onChange={handlePasswordChange}
        error={badPassword}
        onKeyPress={handleKeypress}
      />
      {working ? (
        <Spinner />
      ) : (
        <div onPointerUp={handleAuth}>
          <Submit />
        </div>
      )}
    </InputWrapper>
  );
}

export default PasswordField;
