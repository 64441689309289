import React, { useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import usePointerClick from '../../hooks/usePointerClick';
import useMouseHovered from '../../hooks/useMouseHovered';
import useHighestQualityVideo from '../../hooks/useHighestQualityVideo';

/**
 * Note: VideoItem has some unique properties:
 * hovered with mouse: show overlay
 * clicked with mouse: trigger videoplayer
 * clicked with touch: show overlay and set as selected
 * clicked again with touch: trigger videplayer
 * check the "canHover" and "selected" varialbes
 */

//transform: ${p => (p.selected ? 'scale(1.05)' : 'scale(1)')};

const VideoPoster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;

  transition: transform 1s;
  background-color: #eeeeee;
  background-image: url(${p => p.backgroundImage});
`;

const VideoInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  > div {
    transition: opacity 0.2s;
    opacity: ${p => (p.selected ? 1 : 0)};
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.5);
  }
`;

const VideoTitle = styled.div`
  position: relative;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  color: white;
  /*font-family: 'Raleway';*/
  font-family: 'PT Sans';
  font-size: 1.125rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  pointer-events: none;
`;

const PlayIcon = styled(({ className, visible }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    visible={visible}
  >
    <circle className="circle" cx="12" cy="12" r="10" />
    <polygon className="play" points="10 8 16 12 10 16 10 8" fill="white" />
  </svg>
))`
  position: relative;
  pointer-events: none;
  width: 36px;
  height: 36px;
  display: ${p => (p.visible ? 'block' : 'none')};
`;

const VideoItemWrapper = styled.div`
  user-select: none;
  position: relative;
  height: 0;
  padding-bottom: 56%; /*16:9*/
  -webkit-tap-highlight-color: transparent; /* for remove highlight */
  transform: ${p => (p.in ? 'translateY(0)' : 'translateY(-20%)')};
  opacity: ${p => (p.in ? '1' : '0')};
  transition: opacity 0.55s, transform 0.55s;
  z-index: ${p => p.z};
  overflow: hidden;

  ${p =>
    p.canHover &&
    `
    > ${VideoInfo} {
      > div {
        &:hover {
          opacity: 1;
        }
      }
    }

    &:hover {
      > ${VideoPoster}{
        transform: scale(1.05);
      }
    }
  `}
`;

/*


 */

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999999;
`;

function VideoItem(props) {
  // this is for setting the "in" animation
  const [isIn, setIsIn] = useState(false);

  const videoItemRef = useRef();
  const mouseHovered = useMouseHovered(videoItemRef);

  // set a timeout timed on it's index in the videoGrid
  // to reveil the video
  useLayoutEffect(() => {
    const inTimeout = setTimeout(() => {
      setIsIn(true);
    }, (props.i + 1) * 30);
    return () => {
      clearTimeout(inTimeout);
    };
  }, []);

  // const handlePointerClick = usePointerClick(props.onPointerUp);

  let showMobileOverlay =
    window.pointerType === 'touch' ||
    (window.pointerType === 'hybrid' && mouseHovered === false);

  const zIndex = 500 - props.i;

  const videoSrc = useHighestQualityVideo(props.video);

  // when a video is clicked (mobiel only)
  const handleVideoClick = usePointerClick(e => {
    e.target.play();
    if (typeof e.target.webkitEnterFullscreen != 'undefined') {
      // This is for Android Stock.
      e.target.webkitEnterFullscreen();
    } else if (typeof e.target.webkitRequestFullscreen != 'undefined') {
      // This is for Chrome.
      e.target.webkitRequestFullscreen();
    } else if (typeof e.target.mozRequestFullScreen != 'undefined') {
      e.target.mozRequestFullScreen();
    }
  });

  let videoClickEvent;
  if (window.pointerType === 'touch') {
    videoClickEvent = { onPointerDown: props.onPointerUp };
  } else {
    videoClickEvent = { onPointerUp: props.onPointerUp };
  }

  //console.log(window.pointerType);

  return (
    <VideoItemWrapper
      in={isIn ? 1 : 0}
      z={zIndex}
      canHover={window.pointerType !== 'touch'}
      selected={mouseHovered || props.selected}
      ref={videoItemRef}
    >
      <VideoPoster
        selected={props.selected}
        backgroundImage={props.video.poster.sizes.medium}
      />
      <VideoInfo selected={props.selected} {...videoClickEvent}>
        {showMobileOverlay ? (
          <div>
            {window.bowser.type === 'mobile' && props.selected && (
              <Video {...handleVideoClick}>
                <source src={videoSrc} type="video/mp4" />
              </Video>
            )}
            <VideoTitle>{props.video.title}</VideoTitle>
            <PlayIcon visible={1} />
          </div>
        ) : (
          <div>
            <VideoTitle>{props.video.title}</VideoTitle>
          </div>
        )}
      </VideoInfo>
    </VideoItemWrapper>
  );
}

export default VideoItem;
