import styled from 'styled-components';

/**
 * Wrapper
 */
export const VideoPlayerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 1);
`;

export const Title = styled.h2`
  font-size: 52px;
  font-weight: normal;
  color: white;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1011;
  opacity: ${p => (p.visible ? 1.0 : 0.0)};
  transition: opacity 1s;
  width: 100%;
  padding-left: ${p => p.theme.textGutter}px;
  padding-right: ${p => p.theme.textGutter}px;
  text-align: center;
  ${p => p.theme.breakTablePortrait} {
    top: 35%;
    font-size: 32px;
  }
  ${p => p.theme.breakMobile} {
    font-size: 22px;
  }
`;

export const Poster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 1000;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center center;
  z-index: 1000;
  opacity: ${p => p.opacity};
`;

export const VideoGrad = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1006;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  > div {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 1)
    );
  }
  transition: opacity 1s;
  opacity: ${p => (p.visible ? 1.0 : 0.0)};
`;

export const BackBtn = styled.svg`
  position: absolute;
  top: 20px;
  left: 20px;
  transform: scale(1);
  width: 36px;
  height: 36px;
  z-index: 1010;
  cursor: pointer;
  opacity: ${p => (p.visible ? '1.0' : '0.0')};
  transition: transform 0.4s, opacity 1s;
  ${p => p.theme.breakTablePortrait} {
    width: 24px;
    height: 24px;
  }
`;

export const ToggleAudioBtn = styled.svg`
  position: absolute;
  top: 20px;
  right: 20px;
  transform: scale(1);
  width: 36px;
  height: 36px;
  z-index: 1012;
  cursor: pointer;
  opacity: ${p => (p.visible ? '1.0' : '0.0')};
  transition: transform 0.4s, opacity 1s;
  ${p => p.theme.breakTablePortrait} {
    width: 24px;
    height: 24px;
  }
`;

export const ScrubberWrapper = styled.div`
  position: absolute;
  z-index: 1011;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  > div {
    position: absolute;
    z-index: 1010;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: black;
    cursor: pointer;
    transition: height 0.2s;
    > div {
      position: absolute;
      z-index: 1011;
      bottom: 0;
      left: 0;
      height: 5px;
    }
    > div:nth-child(1) {
      width: ${p => p.progress + '%'};
      background-color: white;
      transition: height 0.2s;
      z-index: 1012;
    }
    > div:nth-child(2) {
      left: calc(${p => p.hover + '%'} - 2px);
      width: 4px;
      background-color: #555555;
      transition: height 0.2s;
      z-index: 1013;
    }
  }

  &:hover {
    > div {
      height: 15px;
      > div {
        height: 15px;
      }
    }
  }
  opacity: ${p => (p.visible ? '1.0' : '0.0')};
  transition: opacity 1s;
`;
